import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import './styles.css';
import { useState } from 'react';
import { useRef } from 'react';
import RegisterShift from '../RegisterShift/RegisterShift';
import axios from 'axios';
import Cookies from "js-cookie";
import { parseISO, format } from 'date-fns';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Grid from '@mui/material/Grid';


const backendUrl = process.env.REACT_APP_BACKEND_URL;


const Agenda = ({  data, patientId, limitDate,patientName, prepaid, comSem, service, institution, doctorId, token, onFinish, onLoading, onCloseAgenda, fichaId, building, observation }) => {
  const [weekStart, setWeekStart] = useState(new Date());
  const calendarRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [tableData, setTableData] = useState(data);
  const [open, setOpen] = useState(false);
  const [tokenStored, setTokenStored] = useState();
  const [events, setEvents] = useState([]);
  const [attentionsDay, setAttentionsDay] = useState([]);
  const [startHour, setStartHour] = useState("08:00:00");
  const [endHour, setEndHour] = useState("20:00:00");
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);

  dayjs.locale('es');
  function transformDataToEvents(data) {
    return data.map(dataItem => {
      // Parsea la fecha en UTC y luego la convierte a la hora local sin aplicar el desplazamiento de zona horaria
      const start = moment.utc(dataItem.date_start).format('YYYY-MM-DDTHH:mm:ss');
      const end = moment.utc(dataItem.date_end).format('YYYY-MM-DDTHH:mm:ss');
  
      return {
        // Usa las fechas convertidas
        start: start,
        end: end,
        extendedProps: dataItem,
        color: '#2c8d99'
      };
    });
  }

const isHighlightedDay = (date) => {
  if (date){
    return attentionsDay.includes(date.dow);
  }
};

const formatDateTime = (dateTimeString) => {
  const date = dayjs(dateTimeString);
  const formattedDate = date.format('dddd D [de] MMMM'); 
  const formattedTime = date.format('HH:mm') + 'hs'; 
  return { formattedDate, formattedTime };
};

const handleDatesSet = (arg) => {
  const currentDate = arg.start;
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + limitDate ? limitDate -1 : 60 -1);

  setIsNextDisabled(currentDate >= maxDate);
  setIsPrevDisabled(currentDate <= today);

};

  useEffect(() => {
    fetchDoctorData(comSem);
  }, []);

  const fetchDoctorData = async (date) => {
    onLoading();
    setTableData([]);
    setAttentionsDay([]);
    setStartHour("");
    setEndHour("");
    try {
        const response = await axios.post(`${backendUrl}/appointments/doctor`,
            JSON.stringify({ os_id: prepaid.id, service_id: service.id, institution_id: institution.id, date: date, doctor_id: doctorId.id }),
            {
                headers: { 'Content-Type': 'application/json', Authorization: token }
            });

            if(response.data.data.appointments !== null){
                setEvents(transformDataToEvents(response.data.data.appointments));
                setTableData(response.data.data.appointments);
                setAttentionsDay(response.data.data.schedule.attention_days);
                setStartHour(convertHourFormat(response.data.data.schedule.start_hour));
                setEndHour(convertHourFormat(response.data.data.schedule.end_hour));
                onFinish();
            }else{
                setTableData([]);
                onFinish();
            }
            
            
    } catch (error) {
        setTableData([]);
        onFinish();
    }
}

  const handleNextClick = () => {
    const newDate = new Date(weekStart);
    newDate.setDate(newDate.getDate() + 7);
    setWeekStart(newDate);
  };

  function formatHour(hour) {
    if ( hour.length === 3) {
        const hours = hour.slice(0, 1);
          const minutes = hour.slice(1);
          return `${hours}:${minutes}`;
      }

    const hours = hour.slice(0, 2);
    const minutes = hour.slice(2);
    return `${hours}:${minutes}`;
}

  function goNext(event) {
    if(isNextDisabled){
      return;
    }
    const calendarApi = calendarRef.current.getApi()
    const currentDate = calendarApi.getDate();
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 7);
    const formattedDate = formatDate(nextDate);
    fetchDoctorData(formattedDate);
    calendarApi.next()
  }


  function formatDate(date) {
    console.log(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que necesitamos agregar 1
    const day = date.getDate();
  
    // Asegurarse de que el mes y el día sean de dos dígitos
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    return `${year}/${formattedMonth}/${formattedDay}`;
  }

  const convertHourFormat = (hour) => {
    console.log(hour, 'hora');
    if(hour.length === 3){
      const hours = hour.slice(0, 1);
      const minutes = hour.slice(1);
      return `${hours}:${minutes
      }:00`;
    }
    if(hour){
      const hours = hour.slice(0, 2);
    const minutes = hour.slice(2, 4);
    return `${hours}:${minutes}:00`;
    }

    
  };
  

  function goBack() {
    if(isPrevDisabled){
      return;
    }
    const calendarApi = calendarRef.current.getApi()
    const currentDate = calendarApi.getDate();
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() - 7);
    const formattedDate = formatDate(nextDate);
    fetchDoctorData(formattedDate);
    calendarApi.prev()
  }

  const handlePrevClick = () => {
    const newDate = new Date(weekStart);
    newDate.setDate(newDate.getDate() - 7);
    setWeekStart(newDate);
  };

  const handleEventClick = (event) => {
    console.log(events)
    if(fichaId === null){
      Swal.fire({
          title: 'No se encuentra la ficha del paciente',
          text: 'Por favor, verifique que el paciente se encuentre registrado en la institución',
          icon: 'error',
        })
        onCloseAgenda()
        return;

  }
    setOpenDialog(true);
    setSelectedEvent(event.event.extendedProps);
    setDoctor(event.event.extendedProps.doctor);
  };

  //const events = transformDataToEvents(tableData);

  

  return (
    <>
    <Grid container spacing={2} sx={{ width: '100%', mt: 3 }}>
    <Grid item xs={12} md={8} mb={'auto'} sx={{overflow: 'auto'} }>
    <FullCalendar
      ref={calendarRef}
      customButtons={{
        next: {
          text: 'next',
          click: goNext,
          disabled:  isNextDisabled,
        },
        prev: {
          text: 'prev',
          click: goBack,
          disabled: isPrevDisabled,
        },
      }}
      hiddenDays={[]}
      headerToolbar={{
        left: 'prev,next',
        center: 'title',
        right: '',
      }}
      slotDuration= '00:15:00'
      allDaySlot= {false}
      locale= 'esLocale'
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      events={events}
      initialDate={comSem} // Inicializa el calendario con la fecha de inicio de la semana
      weekends={true}
      eventClick={handleEventClick}
      slotMinTime={startHour ? startHour : "08:00:00"}
      slotMaxTime={endHour ? endHour : "20:00:00"}
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      }}
      eventTimeFormat={{
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short',
      }}
      dayCellClassNames={(date) => isHighlightedDay(date) ? 'highlighted-day' : ''}
      datesSet={handleDatesSet}
    />
    </Grid>
    <Grid item xs={12} md={4} mt={'top'}>
      <Card sx={{ width: '100%', marginLeft: { xs: 0, md: 2 } }}>
        <CardContent>
          <Box sx={{ maxHeight: 470, overflow: 'auto' }}>
            <List>
              {events.map((event, index) => {
                const { formattedDate, formattedTime } = formatDateTime(event.start);
                return (
                  <ListItem key={index}>
                    <ListItemText primary={formattedDate} secondary={formattedTime} />
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleEventClick({ event })}
                      sx={{ marginLeft: 2 }}
                    >
                      Sacar Turno
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
    {openDialog && selectedEvent && (
        <RegisterShift
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedEvent(null);
          }}
          doctor={doctor}
          date={selectedEvent.date}
          hour={formatHour(selectedEvent.hour_start)}
          institution={selectedEvent.institution}
          service={selectedEvent.service}
          prepaid={prepaid}
          patientName={patientName}
          cronoId={selectedEvent.cro_id}
          patientId={patientId}
          onCloseAgenda={onCloseAgenda}
          fichaId={fichaId}
          building={building}
          observation={observation}
        />
      )}
</>
    
  );
};

export default Agenda;