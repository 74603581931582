import { useRef, useState, useEffect } from "react";
import { Box } from "@mui/system";
import Navbar from "../navBar/Navbar";
import { Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import NavbarListDrawer from "../navBar/NavListDrawer";
import { Button, Stepper, Step, StepLabel, Container } from "@mui/material";
import Cookies from "js-cookie";
import SecondStep from "../../steps/secondStep";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import AppNavBar from "../AppBar/AppNavBar";
import { set } from "date-fns";
import { el } from "date-fns/locale";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const GET_PREPAIDS_URL = `${backendUrl}/patient/dni/prepaids`;
const GET_PATIENTS_URL = `${backendUrl}/user/userID/patients`;
const GET_INSTITUCIONS_URL = `${backendUrl}/institutions/os/8337`;

const steps = ["Servicio", "Doctor"];
const Form = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [token, setToken] = useState();
  const [pacientes, setPacientes] = useState();
  const [firstPatient, setFirstPatient] = useState();
  const [observation, setObservation] = useState();
  const [formData, setFormData] = useState({
    isPatientSelected: false,
    isPrepaidsSelected: false,
    isInstitutionSelected: false,
    isServiceSelected: false,
    paciente: null,
    obraSocial: null,
    institucion: null,
    service: null,
  });
  const [prepaids, setPrepaids] = useState([]);
  const [instituciones, setInstituciones] = useState();
  const [userId, setUserId] = useState();
  const [services, setServices] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedPrepaids, setSelectedPrepaids] = useState();
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [selectedService, setSelectedService] = useState();
  const [fichaId, setFichaId] = useState();
  const [storedFichaId, setStoredFichaId] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [limitDate, setLimitDate] = useState();
  const [selectedFichaId, setSelectedFichaId] = useState();
  const [hasFichaId, setHasFichaId] = useState(false);
  const [useStoredFichaId, setUseStoredFichaId] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const shouldAddMt = isSmallScreen || isMediumScreen || isExtraSmallScreen || isLargeScreen

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSpinnerClose = () => {
    setOpenLoader(false);
  };

  const handlePatient = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      isPatientSelected: true,
      paciente: value.id,
      isPrepaidsSelected: false,
      isServiceSelected: false,
      isInstitutionSelected: false,
    }));
    setSelectedPatient(value);
    getInstitutions(value.id);
    setSelectedInstitution(null)
    setSelectedFichaId(value.id);
  };

  const handlePrepaid = (event, value) => {    
    if(value.is_active == false){
      Swal.fire({
        title: 'Obra social inactiva',
        text:  'La obra social seleccionada se encuentra suspendida para esta institución.',
        icon: 'error',
      })
      setFormData((prevData) => ({
        ...prevData,
        isPrepaidsSelected: false,
      }));
    }
    else{
      setFormData((prevData) => ({
        ...prevData,
        isPrepaidsSelected: true,
        obraSocial: value.id,
        isServiceSelected: false,
      }));
      setSelectedPrepaids(value);
      value.observation ? setObservation(value.observation) : setObservation(null);
    }
    setSelectedService(null);

  };

  const handleInstituciones = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      isInstitutionSelected: true,
      institucion: value.id,
      isPrepaidsSelected: false,
      isServiceSelected: false,
    }));
    setHasFichaId(false);
    getPrepaids(value.id);
    setSelectedInstitution(value);
    getServices(value.id);
    setSelectedPrepaids(null);
    setSelectedService(null);
    setLimitDate(value.limit_days);
  };

  const handleService = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      isServiceSelected: true,
      service: event.target.value,
    }));
    setSelectedService(value);
  };

  const getServices = async (institutionId) => {
    try {
      const response = await axios.get(`${backendUrl}/services/institution/${institutionId}`, {
        headers: {
          Authorization: token,
        },
      });
      setServices(response.data.data);
      if(response.data.data == null){
        Swal.fire({
          title: 'Error al buscar servicios',
          text:  'No se encontraron servicios para la obra social seleccionada',
          icon: 'error',
        })
        setFormData((prevData) => ({
          ...prevData,
          isPrepaidsSelected : false
        }));
      }
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        isPrepaidsSelected : false
      }));
    }
  };

  useEffect(() => {
    const storedToken = Cookies.get("token");
    const storedId = Cookies.get("id");
    const fichaId = Cookies.get("fichaId");
    if (storedToken && storedId && fichaId) {
      setStoredFichaId(fichaId);
      setToken(storedToken);
      setUserId(storedId);
    }
  }, []);

  useEffect(() => {
    if (token) {
      getPacientes();
    }
  }, [token]);

  const getPacientes = async () => {
    try {
      const response = await axios.get(`${backendUrl}/user/${userId}/patients`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.data?.data.length > 0) {
        setFirstPatient(response.data?.data[0]);
        setPacientes(response.data?.data);
      }
    } catch (error) {
      navigate('/home', { state: { msj: 'No se encontraron pacientes para sacar turno' } });
    }
  };

  const getPrepaids = async (institucionId) => {
    try {
      const response = await axios.get(`${backendUrl}/prepaids/institution/${institucionId}/patient/${selectedFichaId}`, {
        headers: {
          Authorization: token,
        },
      });
      setPrepaids(response.data.data);
      if(response.data.data == null){
        Swal.fire({
          title: 'Error al buscar obra social',
          text:  'No se encontraron obras sociales para la institucion seleccionada',
          icon: 'error',
        })
        setFormData((prevData) => ({
          ...prevData,
          isInstitutionSelected : false
        }));
      }
      
    } catch (error) {
      Swal.fire({
        title: 'Error al buscar obra social',
        text:  'No se encontraron obras sociales para la institucion seleccionada',
        icon: 'error',
      })
      setFormData((prevData) => ({
        ...prevData,
        isInstitutionSelected : false
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getInstitutions = async (fichaId) => {
    try {
      const response = await axios.get(`${backendUrl}/institutions/os/patient/${fichaId}`, {
        headers: {
          Authorization: token,
        },
      });
      setInstituciones(response.data.data);
      if(response.data.data == null){
        Swal.fire({
          title: 'Paciente sin obras sociales vinculadas',
          text:  'Se redirigirá al menú de obras sociales',
          icon: 'info',
        }).then(() => {
          navigate('/register-prepaids');
        })
        setFormData((prevData) => ({
          ...prevData,
          isPatientSelected : false
        }));
      }
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        isPatientSelected : false
      }));
    }
  };

  

  

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
              <Autocomplete
                noOptionsText="No hay pacientes"
                value={selectedPatient}
                disableClearable
                id="checkboxes-tags-demo"
                options={pacientes}
                getOptionLabel={(option) => `${option.name} ${option.surname}`}
                style={{ width: 500 }}
                onChange={handlePatient}
                renderInput={(params) => <TextField {...params} label="Paciente" />}
              />
            </Box>
            <br />
            {formData.isPatientSelected && (
              <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
                <Autocomplete
                  noOptionsText="No hay instituciones"
                  value={selectedInstitution}
                  disableClearable
                  id="checkboxes-tags-demo"
                  options={instituciones}
                  getOptionLabel={(option) => option.short_name}
                  style={{ width: 500 }}
                  onChange={handleInstituciones}
                  renderInput={(params) => <TextField {...params} label="Institución" />}
                />
              </Box>
            )}
            <br />
            {formData.isInstitutionSelected && (
              <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
                <Autocomplete
                  noOptionsText="No hay obras sociales"
                  value={selectedPrepaids}
                  disableClearable
                  id="checkboxes-tags-demo"
                  options={prepaids}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: 500 }}
                  onChange={handlePrepaid}
                  renderInput={(params) => <TextField {...params} label="Obra social" />}
                />
              </Box>
            )}
            <br />
            {formData.isPrepaidsSelected && (
              <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
                <Autocomplete
                  noOptionsText="No hay servicios"
                  value={selectedService}
                  disableClearable
                  id="checkboxes-tags-demo"
                  options={services}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: 500 }}
                  onChange={handleService}
                  renderInput={(params) => <TextField {...params} label="Servicios" />}
                />
              </Box>
            )}
          </>
        );
      case 1:
        return (
          <div>
            
              <SecondStep
                token={token}
                prepaid={selectedPrepaids}
                services={selectedService}
                institucion={selectedInstitution}
                patientName={selectedPatient}
                onFinish = {() => setOpen(false)}
                onLoading = {() => setOpen(true)}
                limitDate={limitDate}
                observation={observation}
              />
            
          </div>
        );
      default:
        return "Paso desconocido";
    }
  };

  return (
    <>
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />

    <AppNavBar />
      <Box
          component="main"
          sx={{
             textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Card variant="outlined"
            sx={{
              width: { xs: "100%", sm: "70%" },
              ml: { xs: "0%", sm: "15%" },
              mt: !isSmallScreen ? "10%" : "40%",
              height: 'auto',
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                transform: 'translateZ(0)'
            }}>
            <CardContent >
        <Box  sx={{ mt: 5, mb: 5 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography variant="h5" gutterBottom>
                  Formulario completado
                </Typography>
                <Button onClick={() => setActiveStep(0)}>Reiniciar</Button>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Atrás
                  </Button>
                  <Button variant="contained" onClick={handleNext} disabled={!formData.isServiceSelected || activeStep=== 1}>
                    { "Siguiente"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardContent>
          </Card>
        </Box>
      </Box>
      {open && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              }
    </>
  );
};

export default Form;