import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate, useParams  } from "react-router-dom";
import PwdForgiven from '../PwdForgiven/PwdForgiven';
import PositionedSnackbar from '../Accesibility/Alert';
import Cookies from "js-cookie";
import Navbar from '../navBar/Navbar';
import { bgcolor } from '@mui/system';
import logo2 from '../../assets/logo2.png'
import OutlinedInput from '@mui/material/OutlinedInput';
import CryptoJS from 'crypto-js';
import config from '../../config.json'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AppNavBar from '../AppBar/AppNavBar';
import hospitalImagen from '../../assets/hospitalFrente.webp'



const backendUrl = process.env.REACT_APP_BACKEND_URL;
const LOGIN_URL = `${backendUrl}/user/login`;

const CHANGE_PWD_URL = `${backendUrl}/user/password/forgiven`;

const secretKey = config.secretKey;
const secretIv = config.secretIv;


const defaultTheme = createTheme();

export default function SignInSide() {
    const navigate = useNavigate();
    const location = useLocation();
    const msj = location.state?.msj || '';

    const userRef = useRef();
    const errRef = useRef();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [errMsg, setErrMsg] = useState();
    const [pwd, setPdw] = useState('')
    const [dni, setDni] = useState('')
    const [forgotPwd, setForgotPwd] = useState(false);
    const [error, setError] = useState({
      error : false,
      message : ''
  })

    const encriptPwd = (pwd) =>{
      // Generar un IV aleatorio una sola vez
  const iv = CryptoJS.enc.Utf8.parse(secretIv);
  const secretKeyEncrypted = CryptoJS.enc.Utf8.parse(secretKey);

  // Encriptar usando la misma clave y el mismo IV
  const encryptedPwd = CryptoJS.AES.encrypt(pwd, secretKeyEncrypted, { iv: iv }).toString();

  // Devolver el IV y el texto cifrado como un objeto
  return {
    iv: iv.toString(CryptoJS.enc.Base64),
    encryptedPwd: encryptedPwd
  };
    }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const encryptedPwd  = encriptPwd(pwd)
    const dniNumber = Number(dni)
    try{
        const response = await axios.post(LOGIN_URL, 
            JSON.stringify({dni: dniNumber, password: encryptedPwd.encryptedPwd}),
            {
                headers:{ 'Content-Type': 'application/json'}
            }
        )
        const tokenFromLocation = response?.data?.data?.token;
        if (tokenFromLocation) {
          Cookies.set("token", tokenFromLocation, { expires: 1 / 24 });
          Cookies.set("id", response?.data?.data?.id, { expires: 1 / 24 });
          Cookies.set("email", response?.data?.data?.email, { expires: 1 / 24 });
          Cookies.set("dni", response?.data?.data?.dni, { expires: 1 / 24 });
          Cookies.set("name", response?.data?.data?.name, { expires: 1 / 24 });
          Cookies.set("lastname", response?.data?.data?.surname, { expires: 1 / 24 });
          Cookies.set("fichaId", response?.data?.data?.ficha_id, { expires: 1 / 24 });
        }
        if(response.data.status_code === 200){
          navigate("/home", {state: {from: location, token: response?.data?.data?.token, dni, id: response?.data?.data?.id }, replace: true});
        }
        if(response.data.status_code === 403){
          Cookies.remove('token');
          navigate("/maintence")
        }

        
    } catch (err){
        if(err.response?.data?.status_code === 400){
            {setErrMsg('contraseña o email vacios')}
        }else if(err.response?.data?.status_code === 404){
            {setErrMsg('Usuario o contraseña incorrectos')}
            
        }else if (err.response?.status === 403){
          navigate("/maintence")
        }else{
            setErrMsg('Error de logueo')
        }

    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateDni = (dni) => {
    const dniRegex = /^\d{6,8}$/;
    if(!dniRegex.test(dni)){
      setError({error : true, message : 'DNI no válido'})
    }
    else{
      setError({error : false, message : ''})
    }
  } 


  const showAlert = () =>{
    if (msj !== '') {
      return(
        <PositionedSnackbar msj={msj}></PositionedSnackbar>
      )
    }
  }

  const showMsgErro = () =>{
    if (errMsg) {
      return(
        <Alert severity="error">{errMsg}</Alert>
      )
    }
  }


  return (
    <>
    {showAlert()}
        <AppNavBar isLogin={true}/>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            display: { xs: 'none', sm: 'block' },
            backgroundImage: 'url('+hospitalImagen+')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square  >
        
        
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >  
          <img
          alt="Remy Sharp"
          src={logo2}
          style={{
            width: '90%', 
            height: 'auto', 
            maxWidth: '500px',
            marginRight: '8px', 
            marginLeft: '16px', 
            marginTop: '80px'
          }}
        />
         <Typography component="h1" variant="h5" sx={{  color: '#1C7C88', width: '90%',height: 'auto', textAlign: 'center'  }}>
              Portal de Turno Online
            </Typography>
           <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 15 }}>
            <Typography component="h1" variant="h5" sx={{  color: '#1C7C88' }}>
              Iniciar Sesión
            </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="dni"
                error={error.error}
                helperText={error.message}
                onChange={(e)=> {setDni(e.target.value);
                   validateDni(e.target.value);
                  }}
                label="DNI"
                name="dni"
                autoComplete="dni"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onChange={(e)=> setPdw(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
                }}
              >
              </TextField>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2,}}
              >
                Ingresar
              </Button>
              {errMsg && showMsgErro()}
              <Grid container mt={3}>
                <Grid item xs>
                  <Link href="/forgiven" variant="body2">
                    Olvide la contraseña
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2" 
                  >
                    {"No tienes una cuenta? Registrate"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            
          </Box>
        </Grid>
      </Grid>
    </>
  );
}


