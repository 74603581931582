import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceIcon from '@mui/icons-material/Face';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ReCAPTCHA from 'react-google-recaptcha';
import { el } from 'date-fns/locale';




const backendUrl = process.env.REACT_APP_BACKEND_URL;
const VERIFY_URL = `${backendUrl}/patient/verify`;


const defaultTheme = createTheme();

export default function Validate() {

    const emailRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();

    const defaultClassname = "verify";

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailError, setEmailError] = useState('');

    const [captchaValido, setCaptchaValido] = useState(false);

    const [dni, setDni] = useState('');
    const [validDni, setValidDni] = useState(false);
    const [dniFocus, setDniFocus] = useState(false);

    const [isVerified, setIsVerified] = useState(false);

    const [errMsg, setErrMSg] = useState('');
    const [success, setSuccess] = useState(false);

    const [idFicha, setIdFicha] = useState();
    const [pwdError, setPwdError] = useState('');
    const [isAllreadyRegistered, setIsAllreadyRegistered] = useState(false);

    const validateEmail = (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
          setEmailError('El correo electrónico no es válido');
          setValidEmail(false);
      } else {
          setEmailError('');
          setValidEmail(true);
      }
  };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(VERIFY_URL,
                JSON.stringify({email: email, dni :Number(dni)}),
                {
                    headers:{ 'Content-Type': 'application/json' }
                })
            setIdFicha(response?.data?.id)
            setIsVerified(true)
            setSuccess(true)
            if(response?.data?.data?.registered === false){
                
                navigate("/register", {state: {from: location, emailProp: email, dni, isVerified: true, idFicha: response?.data?.data.id, name: response?.data?.data.name, surname: response?.data?.data.surname, institution_id: response?.data?.data.institution_id}, replace: true});
            }
            else{
              setIsAllreadyRegistered(true)
              setErrMSg('El paciente ya se encuentra registrado en el sistema')
            }
        }
        catch (err) {
            if(!err?.response){
                setErrMSg('No server Response')
            } else if (err.response?.data?.status_code === 404){
                setErrMSg(" Los datos ingresado no corresponden a un paciente registrado en el sistema")
            } else{
                setErrMSg('Error al buscar el paciente')
            }
        }
    }

    const location = useLocation();

  const showAlert = () =>{
    return(
        <Alert severity="error" mt={13}>{errMsg}</Alert>
    )
  }

  const captcha = useRef(null)

  const onChange = (value) =>{
    if(captcha.current.getValue()){
      setCaptchaValido(true)
    }
  }

  return (
    <>
        {success && !isAllreadyRegistered ? (
            <Navigate to="/register" state={{ from: location, isVerified: true, emailProp : email, dni }} replace></Navigate>
        ):
        (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <FaceIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Verificar paciente
            </Typography>
              <Paper
            elevation={3}
            style={{
              padding: '20px',
              margin: '20px 0',
              backgroundColor: '#e1f5fe', 
              borderLeft: '6px solid #2196f3',
            }}
          >
            <Typography variant="body2">
              Si desea crear una cuenta, debe ser paciente de la institución y contar <br />
              con un correo electrónico cargado en nuestro sistema y asociado a su DNI. <br />
              En caso de no cumplir con los requisitos mencionados, deberá comunicarse al <bold>4106500</bold>.
            </Typography>
          </Paper>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                onChange={(e)=> {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
              }}
                error={emailError}
                helperText={emailError}
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="Dni"
                label="DNI"
                type="number"
                id="dni"
                error={pwdError}
                helperText={pwdError}
                onChange={(e) => {
                  const value = e.target.value;
                  setDni(value);
                  if (!(/^\d{6,9}$/.test(value))) {
                    setPwdError('El DNI debe contener entre 6 y 9 caracteres numéricos.');
                  } else {
                    setPwdError('');
                  }
              }}
              />
              <Button
                disabled={email === '' || dni === '' || captchaValido === false || pwdError !== ''}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verificar
              </Button>
              <ReCAPTCHA
        ref={captcha}
        sitekey='6LdRf-UoAAAAANTx6wDCBc7okQ9WkLc1xdLH9CGR'
        onChange={onChange}
        
        >

        </ReCAPTCHA>
        <Box sx={{ mt : 3 }}>
              {errMsg && showAlert()}
        </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      
        )}
    </>
    
  );
}