import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceIcon from '@mui/icons-material/Face';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import config from '../../config.json'
import CryptoJS from 'crypto-js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';




const PWD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RECOVERY_URL = `${backendUrl}/user/password/reset`;

const defaultTheme = createTheme();
const secretKey = config.secretKey;
const secretIv = config.secretIv;


const PwdRecovery = () =>{

    const navigate = useNavigate();
    const location = useLocation();
    const {token, id} = useParams();

    const [pwd, setPwd] = useState('');

    const [shouldDisabled, setShouldDisabled] = useState(true);


    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatchPwd, setValidMatchPwd] = useState({
        error : false,
        message : ''
    });
    const [error, setError] = useState({
        error : false,
        message : ''
    })

    const [errMsg, setErrMSg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
      const result = PWD_REGEX.test(pwd)
      setError({
          error : result ? false : true,
          message : result ? '' : 'Ingresar al menos 1 mayúscula, 1 número y 1 caracter especial'
      });
      const match = pwd === matchPwd;
      setValidMatchPwd({
          error : !match,
          message : match ? '' : 'Las contraseñas no coinciden'
      })
      setShouldDisabled(!result || !match);
  }, [pwd, matchPwd])

  useEffect(() => {
    setError({
      error : false,
      message : ''
    })
  }, [])

  const encriptPwd = (pwd) =>{
    // Generar un IV aleatorio una sola vez
const iv = CryptoJS.enc.Utf8.parse(secretIv);
const secretKeyEncrypted = CryptoJS.enc.Utf8.parse(secretKey);

// Encriptar usando la misma clave y el mismo IV
const encryptedPwd = CryptoJS.AES.encrypt(pwd, secretKeyEncrypted, { iv: iv }).toString();

// Devolver el IV y el texto cifrado como un objeto
return {
  iv: iv.toString(CryptoJS.enc.Base64),
  encryptedPwd: encryptedPwd
};
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    const handleSubmit = async (e) =>{
        e.preventDefault();

        const encryptedPwd  = encriptPwd(pwd)
        const encryptedPwdMatch = encriptPwd(matchPwd)


        try{
            const response = await axios.put(RECOVERY_URL, 
                JSON.stringify({token, user: Number(id), password: encryptedPwd.encryptedPwd, password_duplicate: encryptedPwdMatch.encryptedPwd}),
                {
                    headers:{ 'Content-Type': 'application/json'}
                }
            );
            if(response.status === 200){
                setSuccess(true)
                navigate("/login", {state: {msj: 'Contraseña cambiada con exito'}, replace: true});
            }
        } catch (err){
            setErrMSg(err.response.data.message)
        }
            
    }

    const showAlert = () =>{
      return(
          <Alert severity="error">Error al cambiar la contraseña</Alert>
      )
    }

    return(
        <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1505751172876-fa1923c5c528?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <FaceIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Modificar contraseña
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password"
                error={error.error}
                helperText={error.message}
                onChange={(e)=> setPwd(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                }}
              ></TextField>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Repetir contraseña"
                type={showPassword ? 'text' : 'password'}
                id="passwordDuplicate"
                error={validMatchPwd.error}
                helperText={validMatchPwd.message}
                onChange={(e)=> setMatchPwd(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                }}
              ></TextField>
              <Button
                disabled = {shouldDisabled}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Cambiar contraseña
              </Button>
              
              {errMsg && showAlert()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    )
    

}

export default PwdRecovery


