import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NuevoTurno from '../NuevoTurno/NuevoTurno';
import { Formik } from 'formik';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export default function StepperContainer({children, initialValues, onSumbit}) {
  const [stepNumber, setStepNumber] = React.useState(0);
  const steps = React.Children.toArray(children);

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = () => {
    setStepNumber(stepNumber + 1);
  }

  const back = () => {
    setStepNumber(stepNumber - 1);
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSumbit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => <form onSubmit={formik.handleSubmit}></form>}
      </Formik>
    </div>
  )
}