import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function PositionedSnackbar(props) {

    
  const [state, setState] = React.useState({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  const {msj} = props

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar anchorOrigin={{ vertical, horizontal }}
  open={open}
  onClose={handleClose}
  message="I love snacks"
  key={vertical + horizontal}>
  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    {msj}
  </Alert>
</Snackbar>
    </Box>
  );
}

