import * as React from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Agenda from './Agenda';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import  Drawer from '@mui/material/Drawer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const AgendaDialog = ({  onClose, isOpen, limitDate,observation, patientId, patientName, prepaid, data, token, service, institution, doctorId, doctoName, comSem, fichaId, building}) => {
    const [open, setOpen] = React.useState(isOpen);
  const [openSpinner, setOpenSpinner] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        onClose();
        setOpen(false);
      };
    if (!open) return null;

    const events = data.map(data => {
        // Convierte la hora de inicio de "HHMM" a "HH:MM"
        const hour_start = `${data.hour_start.slice(0, 2)}:${data.hour_start.slice(2)}`;

        // Combina la fecha y la hora de inicio en una fecha ISO 8601
        const start = new Date(`${data.date}T${hour_start}:00`);

        // Asume que cada evento dura una hora
        const end = new Date(start.getTime() + 60 * 60 * 1000);

        return {
            //start: start.toISOString(),
            //end: end.toISOString(),
            // puedes agregar más campos aquí si necesitas
        };
    });

    const handleAppointmentSelect = (appointment) => {
      setSelectedAppointment(appointment);
      setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
      setDrawerOpen(false);
  };
  
      
      const weekStart = new Date('2024-08-26'); // Fecha de inicio de la semana
    
    return (
        <>
        
        <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {doctoName}
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Agenda comSem={comSem} 
        data={data}
        patientId={patientId} 
        patientName={patientName} 
        prepaid={prepaid} 
        service={service} 
        institution={institution}
        doctorId={doctorId}
        token={token}
        onLoading = {() => setOpenSpinner(true)}
        onFinish = {() => setOpenSpinner(false)}
        onCloseAgenda = {handleClose}
        fichaId = {fichaId}
        building = {building}
        observation = {observation}
        limitDate = {limitDate}
        
        />
        {openSpinner && 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openSpinner}
                onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </Dialog>
        </>
    )
    }

export default AgendaDialog;