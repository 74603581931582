import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../navBar/Navbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Cookies from "js-cookie";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const VALIDATE_EMAIL_URL = `${backendUrl}/user/link/patient/validate`


const EmailVerificated = () => {

    const { id, vinculo_id, token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
  const [userToken, setToken] = useState();



    const handleClose = () => {
        setOpen(false);
      };

      
  

    useEffect(() => {
        const emailVerified = async () => {
            try {
              const storedToken = await Cookies.get("token");
              if (storedToken) {
                setToken(storedToken);
              }
                if(storedToken){
                  const response = await axios.post(VALIDATE_EMAIL_URL,
                    JSON.stringify({ vinculo_id: Number(vinculo_id), token :token}),
                    {
                      headers:{
                        Authorization: storedToken,
                        'Content-Type': 'application/json'
                      }
                    })
                    if (response.status === 200) {
                        setLoading(false);
                        setOpen(true);
                        Swal.fire({
                          title: 'Vinculación exitosa',
                          text: 'Se ha asignado un paciente a tu usuario',
                          icon: 'success',
                        }).then(() => {
                          navigate('/home');
                        })
                      }
                }
            }
            catch (err) {
                Swal.fire({
                    title: 'Error al verificar el correo',
                    text: 'Hubo un error al verificar el correo con el link enviado',
                    icon: 'error',
                  }).then(() => {
                    navigate('/home');
                  })
                  setLoading(false);
                  setOpen(true);
            }
        }
        emailVerified()
    },[id, token, vinculo_id])

    return (
        <>
            <Navbar />
            {loading && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              }
        </>
    )

}

export default EmailVerificated