import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { FormControl, Grid, Button, Container, Typography  } from '@mui/material';
import { useFormik } from 'formik';
import { Box, width } from '@mui/system';
import Swal from 'sweetalert2';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import AppNavBar from "../AppBar/AppNavBar";
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceIcon from '@mui/icons-material/Face';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import config from '../../config.json'
import CryptoJS from 'crypto-js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import AppBar from '@mui/material/AppBar';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const defaultTheme = createTheme();
const PWD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
const REGISTER_URL = `${backendUrl}/user/register`
const secretKey = config.secretKey;
const secretIv = config.secretIv;


export default function RegisterUser(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const {isVerified, emailProp, dni, idFicha, name, surname, institution_id} = location.state ?? { isVerified: false };

    const isUserVerify = () => {
        setUserVerify(true)
    }


    const [email, setEmail] = useState(emailProp);
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [emailError, setEmailError] = useState('');



    const [userVerify , setUserVerify] = useState(isVerified)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [pwd, setPwd] = useState('');
    const [validPWd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatchPwd, setValidMatchPwd] = useState({
        error : false,
        message : ''
    });
    const [matchPwdFocus, setMatchPwdFocus] = useState(false);

    const [errMsg, setErrMSg] = useState('');
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState({
        error : false,
        message : ''
    })

    const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const encriptPwd = (pwd) =>{
    // Generar un IV aleatorio una sola vez
const iv = CryptoJS.enc.Utf8.parse(secretIv);
const secretKeyEncrypted = CryptoJS.enc.Utf8.parse(secretKey);

// Encriptar usando la misma clave y el mismo IV
const encryptedPwd = CryptoJS.AES.encrypt(pwd, secretKeyEncrypted, { iv: iv }).toString();

// Devolver el IV y el texto cifrado como un objeto
return {
  iv: iv.toString(CryptoJS.enc.Base64),
  encryptedPwd: encryptedPwd
};
  }


    useEffect(() => {
        const result = PWD_REGEX.test(pwd)
        setError({
            error : !result,
            message : result ? '' : 'Ingresar al menos 1 mayúscula, 1 número y 1 caracter especial'
        });
        const match = pwd === matchPwd;
        setValidMatchPwd({
            error : !match,
            message : match ? '' : 'Las contraseñas no coinciden'
        })
    }, [pwd, matchPwd])

    useEffect(() => {
        setError({
            error : false,
            message : ''
        })
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        setOpen(true);
        const idNumber = Number(idFicha)
        const dniNumber = Number(dni)
        const encryptedPwd  = encriptPwd(pwd)
        const encryptedPwdMatch = encriptPwd(matchPwd)

        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ficha_id: idNumber ,email: email, password: encryptedPwd.encryptedPwd, password_duplicate: encryptedPwdMatch.encryptedPwd, dni: dniNumber, institution_id: institution_id}),
                {
                    headers:{ 'Content-Type': 'application/json' }
                })
            navigate("/finish-registration");
            setOpen(false);
            setLoading(false)
        }
        catch (err){
            setLoading(false)
            Swal.fire({
              title: 'Error al crear la cuenta',
              text: errMsg || 'Error al crear el usuario',
              icon: 'error',
            }).then(() => {
              navigate('/home');
            })
    
        }
    }

  const showAlert = () =>{
    return(
        <Alert severity="error">{errMsg}</Alert>
    )
  }

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
        setEmailError('El correo electrónico no es válido');
        setValidEmail(false);
    } else {
        setEmailError('');
        setValidEmail(true);
    }
};

return (
  <>
  {!isVerified ? (
            <Navigate to="/verify"  replace />
        ) : (
  <>
  <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#1C7D89' }}>
        <Toolbar>
          
        </Toolbar>
      </AppBar>
    </Box>
    <Box sx={{ display: 'flex' }}>
  <CssBaseline />
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      ml: 8
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', mt: 15 }}>
      <Typography component="h1" variant="h3" sx={{ maxwidth: '350px', color: '#1c7d89' }}>
        CREAR NUEVA CUENTA
      </Typography>
      <Typography variant="subtitle1" sx={{ color: '#030303' }}>
        Para crear una cuenta, debe ser paciente de la institución. En caso de no ser paciente, por favor comuníquese al 4106500
      </Typography>
    </Box>

    <TextField
      margin="normal"
      required
      name="email"
      label="Email"
      type="text"
      id="email"
      value={email}
      onChange={(e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
      }}
      error={emailError !== ''}
      helperText={emailError}
      sx={{ width: '60%' }}
    />
    <TextField
      margin="normal"
      required
      sx={{ width: '60%' }}
      name="password"
      label="Contraseña"
      type={showPassword ? 'text' : 'password'}
      id="password"
      error={error.error}
      helperText={error.message}
      onChange={(e) => setPwd(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
    <TextField
      margin="normal"
      required
      sx={{ width: '60%' }}
      name="passwordDuplicate"
      label="Repetir contraseña"
      type={showPassword ? 'text' : 'password'}
      id="passwordDuplicate"
      error={validMatchPwd.error}
      helperText={validMatchPwd.message}
      onChange={(e) => setMatchPwd(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button
        size="medium"
        disabled={error.error || validMatchPwd.error || pwd === '' || matchPwd === '' || emailError !== ''}
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, width: '35%', mr: '15%' }}
        onClick={handleSubmit}
      >
        Registrarme
      </Button>
    </Box>
    {loading && (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )}
    <Box sx={{ mt: 3 }}>
      {errMsg && showAlert()}
    </Box>
  </Box>
</Box>
</>
        )}
  </>
);
}
