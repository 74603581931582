import './App.scss';
import React from 'react';
import Register from './Components/Register/Register';
import Login from './Components/Login/Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {AuthProvider} from './context/AuthProvider';
import Layout from './Components/Layout';
import Home from './Components/Home/Home';
import RequireAuth from './Components/RequireAuth';
import Validate from './Components/Validate/Validate';
import PwdRecovery from './Components/PwdRecovery/PwdRecovery';
import PwdForgiven from './Components/PwdForgiven/PwdForgiven';
import CheckValidate from './Components/CheckValidate/CheckValidate';
import FinishRegistration from './Components/FinishRegistration/FinishRegistration';
import Navbar from './Components/navBar/Navbar';
import NuevoTurno from './Components/NuevoTurno/NuevoTurno';
import StepperContainer from './Components/Stepper/StepperContainer';
import Form from './Components/Form/Form';
import VerifyBeneficiary from './Components/VerifyBeneficiary/VerifyBeneficiary';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EmailVerificated from './Components/EmailVerificated/EmailVerificated';
import RegisterPrepaids from './Components/RegisterPrepaids/RegisterPrepaids';
import MyProfile from './Components/MyProfile/MyProfile';
import NavbarListDrawer from './Components/navBar/NavListDrawer';
import { Box } from "@mui/system";
import { Grid } from '@mui/material';
import AllAppoinments from './Components/AllAppoinments/AllAppoinments';
import RegisterUser from './Components/RegisterUser/RegisterUser';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import Appointments from './Components/Appoinments/Appoinments';
import Maintence from './Components/Maintence/Maintence';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c8d99',
    },
  },
});


export const userContext = React.createContext();
function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/register" element={<RegisterUser />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Validate />} />
        <Route path="/user/:id/reset-password/:token" element={<ChangePassword />} />
        
        <Route path="/forgiven" element={<ResetPassword />} />
        <Route path="/user/:id/validate-email/:validation_code" element={<CheckValidate />} />
        <Route path="/finish-registration" element={<FinishRegistration />} />
        <Route path="/nuevoturno" element={<NuevoTurno />} />
        <Route path="/stepper" element={<StepperContainer />} />
        <Route path="/maintence" element={<Maintence />} />
        

        {/* private routes */}
        <Route element={<RequireAuth />}>
          <Route path="/user/:id/link/:vinculo_id/token/:token" element={<EmailVerificated />} />
          <Route path="/verify-beneficiary" element={<VerifyBeneficiary />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/form" element={<Form />} />
          <Route path="/register-prepaids" element={<RegisterPrepaids />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/allappointments" element={<AllAppoinments />} />
          <Route path="/appointments" element={<Appointments />} />
        </Route>
      </Route> 
    </Routes>
    </ThemeProvider>
    </>
  );
}

export default App;
