import { useEffect, useRef, useState } from "react"
import ReactMapGL, { Marker } from 'react-map-gl';
import {Map, } from 'mapbox-gl'
import config from '../../config.json'
import RegisterShift from "../RegisterShift/RegisterShift";


const MapView = () => {

    const MAPBOX_TOKEN = config.MapBloxToken
    useEffect(() => {
        const map = new Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-64.16426675690963, -31.4093333054629],
          zoom: 14
        });
    
        return () => {
          map.remove();
        };
      }, []);
    
      return (
        <div id="map" style={{ width: '280px', height: '280px' }}></div>
      );
}

export default MapView