import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate, useParams  } from "react-router-dom";
import PwdForgiven from '../PwdForgiven/PwdForgiven';
import PositionedSnackbar from '../Accesibility/Alert';
import { Snackbar } from '@mui/material';

const defaultTheme = createTheme();
const FinishRegistration = () => {

  return (
    <>
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://www.lavoz.com.ar/resizer/v2/EMRXZX37OBCPNMBR6M4NM4SCDM.jpg?quality=75&smart=true&auth=e9c470183a067bf2d839ce6351d6a92cb6a34930540f53c443591f2c9e5efa50&width=430&height=242)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <DoneIcon />
            </Avatar>
            <Box component="form" noValidate  sx={{ mt: 1 }}>
              <Grid container mt={3}>
              <Paper
          elevation={3}
          style={{
            padding: '20px',
            margin: '20px 0',
            backgroundColor: '#f3f3f3',
            borderLeft: '6px solid #4caf50', 
          }}
        >
            <Typography variant="body1">
            ¡El correo ha sido enviado! Por favor, verifica tu bandeja de entrada.
          </Typography>
        </Paper>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </>
  );
}

export default FinishRegistration