export const formatDate = (date) => {
    var fecha = new Date(date);
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1;
    var año = fecha.getFullYear();
    var fechaFormateada = dia + '/' + mes + '/' + año;
    
    return fechaFormateada;
    }

export const formatHour = (hour) => {
    if ( hour.length === 3) {
        const hours = hour.slice(0, 1);
          const minutes = hour.slice(1);
          return `${hours}:${minutes}`;
      }
  
      const hours = hour.slice(0, 2);
      const minutes = hour.slice(2);
      return `${hours}:${minutes}`;
    }