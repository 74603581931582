import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate, useParams  } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import Cookies from "js-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import MapView from '../MapView/MapView';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const RegisterShift = (props) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const { open, onClose, doctor, date, hour, institution, service, prepaid, patientName, cronoId, patientId, onCloseAgenda, fichaId, building, observation } = props
    const [openDialog, setOpenDialog] = useState(open)
		const [token, setToken] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




		useEffect(() => {
			const storedToken = Cookies.get("token");
			if (storedToken) {
				setToken(storedToken);
			}
		}, [setToken]);

		const cloeseDialog = () => {
			setOpenDialog(false)
			onClose()
		}

		const handleClose = () => {
			setOpenLoader(false);
		};

		const onHandleRegister = async (e) => {
            if (onCloseAgenda) {
                onCloseAgenda();
            }
            try {
                const response = await axios.post(`${backendUrl}/appointment/create`,
                    JSON.stringify({ service_id: service.id, doctor_id: doctor.doc_geclisa_id, prepaid_id: prepaid.id, cronogram_id: cronoId, date: date, hour_start: hour, patient_id: fichaId, institution_id: institution.id, default_patient_id: patientId }),
                    {
                        headers: { 'Content-Type': 'application/json', Authorization: token }
                    });
                
                    cloeseDialog()
                    if(response.data.status_code === 403){
                        Swal.fire({
                            title: 'Error al registrar turno',
                            text: 'Se ha alcanzado el número máximo de turnos registrados por día.',
                            icon: 'error',
                        }).then(() => {
                            navigate('/home');
                        })
                    }
                    else{
			Swal.fire({
				title: 'Turno asignado Correctamente',
				text: 'Se ha registrado el turno correctamente',
				icon: 'success',
			}).then(() => {
				navigate('/home');
			})
                    }

                
            } catch (error) {
                cloeseDialog()
                if(error.response.status === 403){
                    Swal.fire({
                        title: 'Error al registrar turno',
                        text: 'Se ha alcanzado el número máximo de turnos registrados por día.',
                        icon: 'error',
                    }).then(() => {
                        navigate('/home');
                    })
                }else{
                    Swal.fire({
                        title: 'Error al registrar turno',
                        text: 'No se ha podido registrar el turno',
                        icon: 'error',
                    }).then(() => {
                        navigate('/home');
                    })
                }
            }

			
		}
    
    return (
        <>
            <Dialog
                open={openDialog && token}
                fullWidth={true}
                TransitionComponent={Transition}
                keepMounted
                onClose={cloeseDialog}
                maxWidth={'sm'}
                aria-describedby="alert-dialog-slide-description"
            >

            <DialogTitle>{"Nuevo turno"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                       Verifique los datos del turno a registrar
                    </DialogContentText>
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', margin: 'auto'}}>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', 
                        margin: 'auto',
                        mr: 2
                        }}
                    >
                    <Typography id="modal-modal-description" sx={{ mt: 2, lineHeight: 2 }}>
                       <b> Paciente: </b>  {patientName} <br />
                       <b>Obra social: </b> {prepaid.name} <br />
                       <b>Fecha: </b>{date} <br />
                       <b>Hora:</b> {hour} <br />
                       <b>Servicio :</b> {service.name} <br />
                       <b>Profesional:</b> {doctor.first_name} {doctor.last_name} <br />
                       <b>Tipo de turno:</b> {"Consulta"} <br />
                       <b>Institución: </b>{institution.short_name} <br />
                       <b>Edificio: </b>{building} <br />
                       <b>Opservación:</b>{observation ? observation : 'Sin observaciones'} <br />
                        </Typography>
                    </Box>
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', 
                        margin: 'auto',
                    }}
                    >
                        <MapView></MapView>

                    </Box>


                    </Box>
                   
                    <DialogActions>
                    <Button onClick={cloeseDialog}>Cancelar</Button>
                    <Button onClick={onHandleRegister}>Aceptar</Button>
                    </DialogActions>
                    {loading && 
                            <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openLoader}
                            onClick={handleClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        }
                    </DialogContent>
            </Dialog>
        </>
    )
}

export default RegisterShift