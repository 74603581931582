import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReCAPTCHA from 'react-google-recaptcha';
import { Stack } from '@mui/system';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const FORGIVEN_URL = `${backendUrl}/user/password/forgiven`

const PwdForgiven = (props) =>{
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";


    const [openModal, setOpenModal] = React.useState(false);
    const [errMsg, setErrMsg] = useState();
    const [email, setEmail] = useState('')
    const [succes, setSuccess] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [captchaValido, setCaptchaValido] = useState(false);
    const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)
    try{
        const response = await axios.post(FORGIVEN_URL, 
            JSON.stringify({email: email}),
            {
                headers:{ 'Content-Type': 'application/json'}
            }
        );
        setOpenModal(true)
        setSuccess(true)
        setLoading(false)
        
    } catch (err){
        setLoading(false)
        if(err.response?.data?.status_code === 400){
            {setErrMsg('El mail ya fue enviado, verifica la casilla de correo no deseado')}
        }else if(err.response?.data?.status_code === 404){
            {setErrMsg('Email incorrecto')}
            
        }else if (err.response?.status === 401){
            setErrMsg('Unauthorized Access')
        }else{
            setErrMsg('Error de logueo')
        }

    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    setOpenModal(false);
  };


  const showMsgErro = () =>{
    if (errMsg) {
      return(
        <Alert severity="error">{errMsg}</Alert>
      )
    } 
  }

  const captcha = useRef(null)

  const onChange = (value) =>{
    if(captcha.current.getValue()){
      setCaptchaValido(true)
    }
  }

  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://www.lavoz.com.ar/resizer/v2/EMRXZX37OBCPNMBR6M4NM4SCDM.jpg?quality=75&smart=true&auth=e9c470183a067bf2d839ce6351d6a92cb6a34930540f53c443591f2c9e5efa50&width=430&height=242)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5" sx={{  color: '#1C7C88' }}>
              Recupero de contraseña
            </Typography>
            <Paper
            elevation={3}
            style={{
              padding: '20px',
              margin: '20px 0',
              backgroundColor: '#e1f5fe', 
              borderLeft: '6px solid #2196f3',
            }}
          >
            <Typography variant="body2">
              Ingrese su correo electrónico al cual se le enviará un enlace <br />
              para poder reestablecer su contraseña. <br />
            </Typography>
          </Paper>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <Stack spacing={2}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={65} />
                        ) : (
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                          />
                        )}
                    {loading ? (
                          <Skeleton variant="rounded" width={300} height={40} />
                        ) : (
                          <Button
                            disabled={!captchaValido || !email}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Ingresar
                          </Button>
                        )}

                  
              {
                loading ? (
                  <></>
                ) : (
                  <ReCAPTCHA
        ref={captcha}
        sitekey='6LdRf-UoAAAAANTx6wDCBc7okQ9WkLc1xdLH9CGR'
        onChange={onChange}
        
        >

        </ReCAPTCHA>
                )
                
              }
              {loading && 
                <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
              }
              {succes ? (
          <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Se le ha enviado un enlace para reestablecer su contraseña <br/>
              Por favor revise su correo electrónico y la casilla de Spam
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      ):(
        errMsg && showMsgErro()
      )}
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}

export default PwdForgiven