import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceIcon from '@mui/icons-material/Face';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import config from '../../config.json'
import CryptoJS from 'crypto-js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const defaultTheme = createTheme();
const PWD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
const REGISTER_URL = `${backendUrl}/user/register`
const secretKey = config.secretKey;
const secretIv = config.secretIv;


export default function Register(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const {isVerified, emailProp, dni, idFicha, name, surname, institution_id} = location.state ?? { isVerified: false };

    const isUserVerify = () => {
        setUserVerify(true)
    }



    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState(emailProp);
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [emailError, setEmailError] = useState('');



    const [userVerify , setUserVerify] = useState(isVerified)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [pwd, setPwd] = useState('');
    const [validPWd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatchPwd, setValidMatchPwd] = useState({
        error : false,
        message : ''
    });
    const [matchPwdFocus, setMatchPwdFocus] = useState(false);

    const [errMsg, setErrMSg] = useState('');
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState({
        error : false,
        message : ''
    })

    const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const encriptPwd = (pwd) =>{
    // Generar un IV aleatorio una sola vez
const iv = CryptoJS.enc.Utf8.parse(secretIv);
const secretKeyEncrypted = CryptoJS.enc.Utf8.parse(secretKey);

// Encriptar usando la misma clave y el mismo IV
const encryptedPwd = CryptoJS.AES.encrypt(pwd, secretKeyEncrypted, { iv: iv }).toString();

// Devolver el IV y el texto cifrado como un objeto
return {
  iv: iv.toString(CryptoJS.enc.Base64),
  encryptedPwd: encryptedPwd
};
  }


    useEffect(() => {
        const result = PWD_REGEX.test(pwd)
        setError({
            error : !result,
            message : result ? '' : 'Ingresar al menos 1 mayúscula, 1 número y 1 caracter especial'
        });
        const match = pwd === matchPwd;
        setValidMatchPwd({
            error : !match,
            message : match ? '' : 'Las contraseñas no coinciden'
        })
    }, [pwd, matchPwd])

    useEffect(() => {
        setError({
            error : false,
            message : ''
        })
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        setOpen(true);
        const idNumber = Number(idFicha)
        const dniNumber = Number(dni)
        const encryptedPwd  = encriptPwd(pwd)
        const encryptedPwdMatch = encriptPwd(matchPwd)

        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ficha_id: idNumber ,email: email, password: encryptedPwd.encryptedPwd, password_duplicate: encryptedPwdMatch.encryptedPwd, dni: dniNumber, institution_id: institution_id}),
                {
                    headers:{ 'Content-Type': 'application/json' }
                })
            navigate("/finish-registration");
            setOpen(false);
            setLoading(false)
        }
        catch (err){
            setLoading(false)
            if(err.response?.data?.status_code === 400){
                {setErrMSg('contraseña o email vacios')}
            }else if(err.response?.data?.status_code === 404){
                {setErrMSg('Usuario o contraseña incorrectos')}
                
            }else if (err.response?.status === 401){
                setErrMSg('Unauthorized Access')
            }else{
                setErrMSg('Error de logueo')
            }
    
        }
    }

  const showAlert = () =>{
    return(
        <Alert severity="error">{errMsg}</Alert>
    )
  }

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
        setEmailError('El correo electrónico no es válido');
        setValidEmail(false);
    } else {
        setEmailError('');
        setValidEmail(true);
    }
};

  return (
    <>
        {!isVerified ? (
            <Navigate to="/verify"  replace />
        )
        : 
        (
        
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1505751172876-fa1923c5c528?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <FaceIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Registrar usuario
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="surname"
                label="Apellido" 
                name="surname"
                autoFocus
                value={surname}
                disabled
              />
            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nombre" 
                name="name"
                autoFocus
                value={name}
                disabled
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="Email"
                label="Email"
                type="text"
                id="email"
                value={email}
                onChange={(e)=> {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
              }}
                error={emailError}
                helperText={emailError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password"
                error={error.error}
                helperText={error.message}
                onChange={(e)=> setPwd(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                }}
              ></TextField>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Repetir contraseña"
                type={showPassword ? 'text' : 'password'}
                id="passwordDuplicate"
                error={validMatchPwd.error}
                helperText={validMatchPwd.message}
                onChange={(e)=> setMatchPwd(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                }}
              ></TextField>
              <Button
                disabled = {error.error  ? true : false || validMatchPwd.error ? true : false || pwd === '' || matchPwd === '' || emailError !== '' } 
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Registrar
              </Button>
              {loading && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              }
              <Box sx={{ mt : 3 }}>
                {errMsg && showAlert()}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
        )}
    </>
  )
    
}

