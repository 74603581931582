import { useRef, useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material"
import axios from "../../api/axios";
import Cookies from "js-cookie";
import Navbar from "../navBar/Navbar"
import NavbarListDrawer from '../navBar/NavListDrawer';
import CssBaseline from '@mui/material/CssBaseline';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme, lighten } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppNavBar from "../AppBar/AppNavBar";
import { styled } from '@mui/material/styles';





const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AllAppoinments = (props) => {

    const [token, setToken] = useState();
    const [tableData, setTableData] = useState([]);
    const [year, setYear] = useState(2024);
    const [dataForm, setDataForm] = useState({
        datePicked: dayjs(new Date()).format('YYYY'),
        selected: -1,
        formatDate: dayjs(new Date()).format('YYYY')
    });
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  
    const marginLeftStyle = isSmallScreen || isMediumScreen || isExtraSmallScreen || isLargeScreen ? { ml: 7 } : {};

    

      const handleDateChange = (newValue) => {
        dayjs(newValue).format('YYYY')
        setDataForm({ ...dataForm, formatDate: dayjs(newValue.$y).format('YYYY'), datePicked: newValue });
        setYear(dayjs(newValue.$y).format('YYYY'))
        getAllAppointments(newValue.$y);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: lighten('#1C7D89', 0.5),
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

      useEffect(() => {
        const storedToken = Cookies.get("token");
        if (storedToken) {
          if (!token) {
            setToken(storedToken);
          }
          getAllAppointments(year);

        }
      }, [setToken, token]);

      function formatHour(hour) {
        if ( hour.length === 3) {
          const hours = hour.slice(0, 1);
            const minutes = hour.slice(1);
            return `${hours}:${minutes}`;
        }
    
        const hours = hour.slice(0, 2);
        const minutes = hour.slice(2);
        return `${hours}:${minutes}`;
    }

    function formatDate(date) {
      var fecha = new Date(date);
      var dia = fecha.getDate();
      var mes = fecha.getMonth() + 1;
      var año = fecha.getFullYear();
  
      var fechaFormateada = dia + '/' + mes + '/' + año;
  
      return fechaFormateada;
  }

      async function getAllAppointments(year) {
        setTableData([]);
        try {
          const response = await axios.get(`${backendUrl}/appointments/all/year/${year}`, {
            headers: {
              Authorization: token,
            },
            
          });
          if(response.data.data !== null){
            
            setTableData(response?.data?.data);
        }
        else if (response.data?.data === null){
          setTableData([]);
        }
        }
          
        
      catch (error) {
        setTableData([]);
      }
      }

    return (
        <>
        <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppNavBar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        <Card variant="outlined" 
            

            sx={{
              width: { xs: "100%", sm: "70%" },
              ml: { xs: "0%", sm: "15%" },
              mt: { xs: "0%", sm: "15%" },
              textAlign: 'center',
              mt: "150px",
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                transform: 'translateZ(0)',
            }}
        >
          <CardContent>

                <Typography variant="h5"
                    sx={{
                    mt: 3,
                    mb: 2,
                    padding: '8px 12px',
                    borderRadius: '4px',
                    
                    }}>Historial de turnos</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", mb:2  }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        defaultValue={dayjs(new Date()).format('YYYY')}
                        label='Selecciona una fecha'
                        openTo="year"
                        value={dataForm.datePicked}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        format="YYYY"
                        views={['year']}
                    />
                </LocalizationProvider>
            </Box>
            {
              tableData.length === 0 ? (
                <Typography variant="h5" color="text.secondary" sx={{ mt: 2 }}>
                  No hay turnos en el historial
                </Typography>
            ):
            
              <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader sx={{ minWidth: 700 }}>
                {
                    tableData.length !== 0 && (

                        <TableHead>
                      <TableRow>
                          <StyledTableCell>Nombre</StyledTableCell>
                          <StyledTableCell>Obra Social</StyledTableCell>
                          <StyledTableCell>Institución</StyledTableCell>
                          <StyledTableCell>Servicio</StyledTableCell>
                          <StyledTableCell>Fecha</StyledTableCell>
                          <StyledTableCell>Hora</StyledTableCell>
                      </TableRow>
                  </TableHead>
                    )
                }
            
                  <TableBody>
                      {tableData.map((row) => (
                          <StyledTableRow key={row.doctor.id}>
                              <StyledTableCell>{row.doctor.first_name || "" }</StyledTableCell>
                              <StyledTableCell>{row.os.name }</StyledTableCell>
                              <StyledTableCell>{row.institution.name}</StyledTableCell>
                              <StyledTableCell>{row.service.name}</StyledTableCell>
                              <StyledTableCell>{formatDate(row.date)}</StyledTableCell>
                              <StyledTableCell>{formatHour(row.hour_start)}</StyledTableCell>
                          </StyledTableRow>
                      ))}
                  </TableBody>
            </Table>
          </TableContainer>
}
           
            </CardContent>
        </Card>
        </Box>
        </Box>
        </>
    )
}

export default AllAppoinments