
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate, useParams  } from "react-router-dom";
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import Cookies from "js-cookie";
import CircularProgress from '@mui/material/CircularProgress';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const REGISTER_PATIENT = `${backendUrl}/user/link/patient`;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const RegisterBeneficiary = ({ open, dni, email, fichaId, institucionId, onClose }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [beneficiary, setBeneficiary] = useState({
        name: '',
        surname: '',
        dni: '',
        email: '',
    });
    const [id, setId] = useState(0);
    const [openDialog, setOpenDialog] = useState(open)
  const [token, setToken] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    const storedToken = Cookies.get("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, [setToken]);


  const handleClose = () => {
    setOpenLoader(false);
  };
       
        async function getPatient() {
          try {
            const response = await axios.get(`${backendUrl}/patient/${dni}`, {
              headers: {
                Authorization: token,
              },
            });
            if (response.status === 200) {
              setBeneficiary({
                name: response.data.name,
                surname: response.data.surname,
                dni: response.data.dni,
                email: email,
              });
              setId(response.data.id);
              setOpenDialog(true);
            }

          } catch (error) {
            setBeneficiary({
              name: '',
              surname: '',
              dni: '',
              email: '',
            });
          }
        }

      useEffect(() => {
        if(token){
          getPatient();
        }
      }, [token]);


      const onHandleRegister = async (e) => {
        e.preventDefault();
        setOpenLoader(true);
        setLoading(true);
        try{
            const response = await axios.post(REGISTER_PATIENT,
                JSON.stringify({ ficha_id: id, institution_id: institucionId}),
                {
                    headers:{ 
                      Authorization: token,
                      'Content-Type': 'application/json'
                    }
                }
            );
            setMessage(response?.data.message);
            if (response.status === 200) {
              setOpenLoader(false);
              setOpenDialog(false)
              setLoading(false)
              Swal.fire({
                title: 'Verificar email',
                text: 'Por favor verifica tu correo para confirmar tu vinculación',
                icon: 'info',
              }).then(() => {
                navigate('/home');
              })
            }

        } catch (err){
          setOpenLoader(false);
          setOpenDialog(false)
          setLoading(false)
          Swal.fire({
            title: 'Error al vincular paciente',
            text: err.response.data.message || 'No se pudo vincular el paciente',
            icon: 'error',
          }).then(() => {
            navigate('/home');
          })
    
        }
      }

      const cloeseDialog = () => {
        setOpenDialog(false)
        onClose()
      }


    return (
      <>
      {token &&
        <Dialog
        open={openDialog && token}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={cloeseDialog}
        maxWidth={'sm'}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Asociar un paciente"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Verifique que los datos del paciente a asociar sean correctos
          </DialogContentText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%', 
              margin: 'auto',
            }}
          >
          <Typography id="modal-modal-description" sx={{ mt: 2, lineHeight: 2 }}>
              Nombre: {beneficiary.name} <br />
              Apellido: {beneficiary.surname} <br />
              DNI: {beneficiary.dni}
            </Typography>
          </Box>
          <DialogActions>
          <Button onClick={cloeseDialog}>Cancelar</Button>
          <Button onClick={onHandleRegister} disabled = {beneficiary.name === '' || beneficiary.dni === ''}>Aceptar</Button>
        </DialogActions>
        {loading && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              }
        </DialogContent>
        
        
      </Dialog>
}
      </>
        
    );
    
}

export default RegisterBeneficiary

