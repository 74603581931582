import React from 'react';
import { Grid, CssBaseline, Typography } from '@mui/material';
import MaintenceImage from '../../assets/Maintence.webp';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MantenimientoLogo from '../../assets/mantenimientoLogo2.png';
const Maintenance = () => {
    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            display: { xs: 'none', sm: 'block' },
            backgroundImage: 'url('+MaintenceImage+')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square  >
        
        
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
          alt="Remy Sharp"
          src={MantenimientoLogo}
          style={{
            width: '90%', 
            height: 'auto', 
            maxWidth: '500px',
            marginRight: '8px', 
            marginLeft: '16px', 
            marginTop: '80px'
          }}
        />
         <Typography component="h1" variant="h5" sx={{  color: '#1C7C88', width: '90%',height: 'auto', textAlign: 'center'  }}>
         Estamos ultimando detalles para brindarte la mejor atención
            </Typography>
           
            
          </Box>
        </Grid>
      </Grid>
    );
};

export default Maintenance;