import { CardMedia, CardActionArea, Box  } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import NuevoTurno from '../../assets/NuevoTurno.jpg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';



const theme = createTheme({
    components: {
      MuiCardMedia: {
        styleOverrides: {
          img: {
            height: '300px',
          },
        },
      },
    },
  });


export default function Cards(){

    return (
        <ThemeProvider theme={theme}>
            <Grid item sx={{mt: 15, pl : 5, display: 'flex', gap: 5}}>
              <Grid item xs={12} md={4}>
                <Link to={{ pathname: '/form',
                    state: { token: '/home' }
                    }}
                    style={{ textDecoration: 'none' }}>
                    <Card sx={{ 
                      width: 345, 
                      mt: 5,
                      transition: "0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        cursor: "pointer",
                      },
                    
                    }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                src={NuevoTurno}
                                alt="Nuevo turno"
                                height="200px"

                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Nuevo Turno
                            </Typography>
                        </CardContent>

                        </CardActionArea>
                        
                    </Card>
                    
                </Link>
                </Grid>
                <Grid item xs={12} md={4}>
                <Link to={{ pathname: '/form',
                    state: { token: '/home' }
                    }}
                    style={{ textDecoration: 'none' }}>
                    <Card sx={{ 
                      width: 345, 
                      mt: 5,
                      transition: "0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        cursor: "pointer",
                      },
                    
                    }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                src={NuevoTurno}
                                alt="Nuevo turno"
                                height="200px"

                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Mis Turnos
                            </Typography>
                        </CardContent>

                        </CardActionArea>
                        
                    </Card>
                    
                </Link>
                </Grid>

                
            </Grid>
        </ThemeProvider>


    )
}