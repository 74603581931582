import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import NavbarListDrawer from './NavListDrawer';
import Logo from '../../assets/Logo.png';
import NavListDrawer from './NavListDrawer';
import { Drawer } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, createTheme, ThemeProvider, useTheme  } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';





function Navbar({isLogin = false}) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const toggleDrawer = () => {
    setOpen(!openDrawer);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

const drawerWidth = 240;

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        backgroundColor: '#222d32',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );
  return (
    < >
        
        <AppBar position="fixed" >
                <Toolbar disableGutters>
                    {!isLogin && (
                      <Box sx={{ display: { sm: 'block', md: 'block', xs: 'block', lg: 'block', xl: 'none' }}}>
                      <IconButton
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          sx={{ mr: 2, ml: 2 }}
                          onClick={() => setOpen(true)}
                      >
                          <MenuIcon />
                      </IconButton>
                      </Box>
                    )}
                    
                    
                    <Avatar alt="Remy Sharp" src={Logo} sx={{ mr: 2, width: 200, height: 60, marginRight: 1, overflow: 'visible', ml: 2}} />
                    
                </Toolbar>
                
                
        </AppBar>
        <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
                <Divider />
                <NavListDrawer />
        </Drawer>
        <Drawer
                      anchor="left"
                      open={open}
                      onClose={() => setOpen(false)}
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                        display: { sm: 'block', md: 'block', xs: 'block', lg: 'block', xl: 'none' }
                      }}
                    >
                      <NavListDrawer />
                  </Drawer>
                  
    </>
  );
}
export default Navbar;