import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import { Box } from "@mui/system"
import Navbar from "../navBar/Navbar"
import { Grid, Typography } from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const NuevoTurno = () => {

    const [paciente, setPaciente] = useState('');

    const handleChange = (event) => {
        setPaciente(event.target.value);
    }

    return (
        <>
            <Navbar />
            <Grid container>
                <Grid item xs={12} sx={{ mt: 8 }}>
                    <Box>
                        <Typography variant="h4">Paso 1</Typography>
                        <Typography variant="subtitle1">Completa los datos para buscar turnos</Typography>
                    </Box>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={paciente}
                            onChange={handleChange}
                            label="Age"
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}

export default NuevoTurno