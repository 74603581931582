
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert, CircularProgress  } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PwdForgiven from '../PwdForgiven/PwdForgiven';
import PositionedSnackbar from '../Accesibility/Alert';
import { Snackbar } from '@mui/material';

const defaultTheme = createTheme();

const CheckValidate = () =>{

    const { id, validation_code } = useParams();
    const navigate = useNavigate();
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const [erroMsg, setErrMsg] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

  
    useEffect(() => {
      async function validateUser() {
        try {
          const response = await axios.get(`${backendUrl}/user/${id}/validate/${validation_code}`);
          if (response.status === 200) {
            navigate('/login', { state: { msj: 'Usuario validado correctamente.' } });
          }
        } catch (error) {

          setErrMsg(true)
          setValidationMessage('Hubo un error al realizar la validación.');
        }
        finally {
            setLoading(false);
          }
      }
  
      validateUser();
    }, [id, validation_code]);
  
    return (
      erroMsg && 
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(https://www.hospitalitaliano.org.ar/images/centros/barrionorteultima.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
                <ErrorIcon />
              </Avatar>
              <Box component="form" noValidate  sx={{ mt: 1 }}>
                <Grid container mt={3}>
                <Paper
          elevation={3}
          style={{
            padding: '20px',
            margin: '20px 0',
            backgroundColor: '#ffecb3',
            borderLeft: '6px solid #f44336',
          }}
        >
          <Typography variant="body1">
            ¡Lo sentimos, ha ocurrido un error al enviar el correo! Por favor, inténtelo de nuevo.
          </Typography>
        </Paper>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  };


export default CheckValidate;