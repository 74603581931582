import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useRef, useState, useEffect } from "react"
import Cookies from "js-cookie";


const RequireAuth = () => {
    const [token, setToken] = useState(Cookies.get("token"));
    const { auth } = useAuth();
    const location = useLocation();
  
    useEffect(() => {
      const storedToken = Cookies.get("token");
      if (storedToken) {
        setToken(storedToken);
      }
    }, []);
  
    return (
      token ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )
    );
  };
  
  export default RequireAuth;