// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-wrap: nowrap;
  width: 130em;
  height: auto;
}

img {
  width: 50em;
  height: 63em;
}

.layout__header {
  display: flex;
  height: 150px;
  width: auto;
  align-items: start;
  background: linear-gradient(rgb(122, 122, 122), rgb(255, 255, 255));
}
.layout__title {
  font-size: 35px;
  text-align: right;
  color: rgb(22, 92, 99);
  margin-left: 290px;
}
.layout__content {
  width: 100%;
}
.layout__image-container {
  display: flex;
  align-items: left;
  width: 80em;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAGA;EACI,WAAA;EACA,YAAA;AAAJ;;AAMI;EACI,aAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,mEAAA;AAHR;AAMI;EACI,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAJR;AAOI;EACI,WAAA;AALR;AAQI;EACI,aAAA;EACA,iBAAA;EACA,WAAA;AANR","sourcesContent":[".App{\n    display: flex;\n    flex-wrap: nowrap;\n    width: 130em;\n    height: auto;\n\n}\n\nimg{\n    width: 50em;\n    height: 63em\n}\n\n\n.layout{\n\n    &__header{\n        display: flex;\n        height: 150px;\n        width: auto;\n        align-items: start;\n        background: linear-gradient(rgb(122, 122, 122), rgb(255, 255, 255));\n    }\n\n    &__title{\n        font-size: 35px;\n        text-align: right;\n        color: rgb(22, 92, 99);\n        margin-left: 290px;\n    }\n\n    &__content{\n        width: 100%;\n    }\n\n    &__image-container{\n        display: flex;            // Usamos Flexbox para controlar la alineación\n        align-items: left; \n        width: 80em;\n      }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
