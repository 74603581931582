import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, Grid, TextField, Container  } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "../../api/axios";
import { useRef, useState, useEffect } from "react"
import Swal from 'sweetalert2';
import RegisterBeneficiary from '../RegisterBeneficiary/RegisterBeneficiary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const VERIFY_URL = `${backendUrl}/patient/verify`;

const AddFamilyModal = ({ show, email, token, onFamilyLink, onClose }) => {
    const [open, setOpen] = React.useState(show);
  const [success, setSuccess] = useState(false);
  const [fichaId, setfichaId ] = useState();
  const [institucionId, setInstitucionId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const initialValues = {
    email: email,
    dni: '',
  };
  const [dni, setDni] = useState();

  const validationSchema = Yup.object({
    dni: Yup.string()
      .required('El DNI es obligatorio')
      .matches(/^\d{6,8}$/, 'El DNI debe tener 8 dígitos numéricos'),
  });

  const onSubmit = async (e) => {
    try {
        const response = await axios.post(VERIFY_URL,
            JSON.stringify({email: email, dni :Number(formik.values.dni)}),
            {
              headers:{
                Authorization: token,
                'Content-Type': 'application/json'
              }
            })
            setDni(formik.values.dni)
            setSuccess(true)
            setfichaId(response.data.data.id)
            setInstitucionId(response.data.data.institution_id)
            onFamilyLink(true, formik.values.dni, true, response.data.data.id, response.data.data.institution_id)
            onClose(false)
            setOpen(false);
    }
    catch (err) {
      setOpen(false)
      onClose(false)
      Swal.fire({
        title: 'Error al encontrar paciente',
        icon: 'error',
      })
    }
};

const handleClose = () => {
  setOpen(false);
  onClose(false);
}

const handleDniChange = (event) => {
  const inputValue = event.target.value;
  if (/^\d*$/.test(inputValue)) {
    formik.handleChange(event);
  }
};

  React.useEffect
  (() => {
  }, [show]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>




      <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth={true}
                keepMounted
                maxWidth={'sm'}
                sx={{ mr: 5 }}
            >

            <DialogTitle>{"Nuevo familiar"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{mr: 5}}>
                    Por favor, ingrese el DNI del paciente que desea vincular 
                    </DialogContentText>
                    <Box sx={{ display: 'flex', width: '100%', margin: 'auto'}}>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', 
                        margin: 'auto',
                        mr: 2
                        }}
                    >
                    <form>
          <Grid container justifyContent="center" >
          
          <Grid item xs={8} sx={{  }}>
            <Grid item sx={{ mb: 3 }}>
            <TextField
                margin="normal"
                fullWidth
                name="Email"
                type="text"
                id="email"
                value={email}
                disabled
              />
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <TextField
                fullWidth
                id="dni"
                name="dni"
                label="DNI"
                value={formik.values.dni}
                onChange={handleDniChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dni && Boolean(formik.errors.dni)}
                helperText={formik.touched.dni && formik.errors.dni}
              />
            </Grid>
            
            </Grid>
          </Grid>
        </form>
                    </Box>
                    </Box>
                   
                    <DialogActions>
                    <Button disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                      onClick={onSubmit}
                        >
                          Vincular familiar
                    </Button>
                    </DialogActions>
                    </DialogContent>
            </Dialog>

    </div>
  );
    
    };

export default AddFamilyModal;