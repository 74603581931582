import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../../api/axios";
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReCAPTCHA from 'react-google-recaptcha';
import { Stack } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';



const backendUrl = process.env.REACT_APP_BACKEND_URL;
const FORGIVEN_URL = `${backendUrl}/user/password/forgiven`

const ResetPassword = (props) =>{
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";


    const [openModal, setOpenModal] = React.useState(false);
    const [errMsg, setErrMsg] = useState();
    const [email, setEmail] = useState('')
    const [succes, setSuccess] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [captchaValido, setCaptchaValido] = useState(false);
    const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)
    try{
        const response = await axios.post(FORGIVEN_URL, 
            JSON.stringify({email: email}),
            {
                headers:{ 'Content-Type': 'application/json'}
            }
        );
        setOpenModal(true)
        setSuccess(true)
        setLoading(false)
        
    } catch (err){
        setLoading(false)
        if(err.response?.data?.status_code === 400){
            {setErrMsg('El mail ya fue enviado, verifica la casilla de correo no deseado')}
        }else if(err.response?.data?.status_code === 404){
            {setErrMsg('Email incorrecto')}
            
        }else if (err.response?.status === 401){
            setErrMsg('Unauthorized Access')
        }else{
            setErrMsg('Error al encontrar el mail')
        }

    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    setOpenModal(false);
  };


  const showMsgErro = () =>{
    if (errMsg) {
      return(
        <Alert severity="error">{errMsg}</Alert>
      )
    } 
  }

  const captcha = useRef(null)

  const onChange = (value) =>{
    if(captcha.current.getValue()){
      setCaptchaValido(true)
    }
  }

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#1C7D89' }}>
          <Toolbar>
            
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Box
    component="main"
    sx={{
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      ml: 8
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', mt: 15 }}>
      <Typography component="h1" variant="h3" sx={{ maxwidth: '350px', color: '#1c7d89' }}>
        OLVIDÉ MI CONTRASEÑA
      </Typography>
    </Box>

    <TextField
                            margin="normal"
                            required
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{ width: '60%' }}
                          />
    
    <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
      <Button
        disabled={!captchaValido || !email}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, width: '15%' }}
        onClick={handleSubmit}
      >
        ENVIAR
      </Button>
    </Box>
    <ReCAPTCHA
        ref={captcha}
        sitekey='6LdRf-UoAAAAANTx6wDCBc7okQ9WkLc1xdLH9CGR'
        onChange={onChange}
        
        >

        </ReCAPTCHA>         
    {loading && (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )}
    {succes ? (
          <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Se le ha enviado un enlace para reestablecer su contraseña <br/>
              Por favor revise su correo electrónico y la casilla de Spam
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      ):(
        <Box sx={{width: "300px", mt: 5}}>
        {errMsg && showMsgErro()}
        </Box>
      )}
  </Box>
  </Box>
    </>
  );
}

export default ResetPassword