import { Outlet } from "react-router-dom";
import "./Layout.scss";
import * as React from "react";
import { styled } from "@mui/system";

const Layout = () => {

    const imageURL = "https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg";
    const Background = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${imageURL})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    });

    return (
        <div >
                <Outlet />
        </div>
    );
}

export default Layout;