// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc-event {
    cursor: pointer;
    transition:  box-shadow 0.3s ease;
  }
  .fc-event:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .fc-v-event .fc-event-main-frame {
    flex-direction: row !important;
  }

  .fc-event-title {
    font-size: 12px;
  }

  .highlighted-day {
    background-color: #d3d3d3!important;
  }

  .fc .fc-scroller-liquid-absolute {
    inset: 0px;
    position: relative;
}

.fc .fc-scrollgrid-liquid {
  height: 50%;
  max-height: 400px;
}

.fc .fc-header-toolbar {
  border: 2px solid #d3d3d3; /* Color gris claro */
  padding: 5px; /* Espaciado opcional */
  border-radius: 5px; /* Bordes redondeados opcionales */
}`, "",{"version":3,"sources":["webpack://./src/Components/AgendaDialog/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iCAAiC;EACnC;EACA;IACE,sBAAsB;IACtB,0CAA0C;EAC5C;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,mCAAmC;EACrC;;EAEA;IACE,UAAU;IACV,kBAAkB;AACtB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,YAAY,EAAE,uBAAuB;EACrC,kBAAkB,EAAE,kCAAkC;AACxD","sourcesContent":[".fc-event {\n    cursor: pointer;\n    transition:  box-shadow 0.3s ease;\n  }\n  .fc-event:hover {\n    transform: scale(1.05);\n    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);\n  }\n\n  .fc-v-event .fc-event-main-frame {\n    flex-direction: row !important;\n  }\n\n  .fc-event-title {\n    font-size: 12px;\n  }\n\n  .highlighted-day {\n    background-color: #d3d3d3!important;\n  }\n\n  .fc .fc-scroller-liquid-absolute {\n    inset: 0px;\n    position: relative;\n}\n\n.fc .fc-scrollgrid-liquid {\n  height: 50%;\n  max-height: 400px;\n}\n\n.fc .fc-header-toolbar {\n  border: 2px solid #d3d3d3; /* Color gris claro */\n  padding: 5px; /* Espaciado opcional */\n  border-radius: 5px; /* Bordes redondeados opcionales */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
